<template>
  <div>
    <v-row class="text-center">
      <v-col class="mb-4">
        <v-container>
          <v-icon>mdi-face-woman</v-icon>
          <br>
          Vielleicht kann dir Elea noch anders weiterhelfen?
          Mache doch gleich den nächsten Test :)
        </v-container>
      </v-col>
    </v-row>
    <br>
    <MoreTests />
  </div>
</template>

<script>
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'PostTest',
  components: {
    MoreTests: lazyLoad('components/MoreTests'),
  },
};
</script>
